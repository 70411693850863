/**
 * Created by woydzl on 08/31/22.
 */
/**
 * Filtering.js
 *
 * filters for filtering display data.
 *
 */

export function applyOutcomeFilters(values, filters) {
    return applyFilters(values, 'outcomes', filters);
}

export function applyJourneyFilters(values, filters) {
    return applyFilters(values, 'journeys', filters);
}

/**
 * Apply filters to values
 * @param values - These values should either be outcomes or journeys
 * @param filterType - Either 'outcomes' or 'journeys'
 * @param filters - A filter map with the following structure:
 * {
 *    filterName: {
 *      appliesTo: Set(['outcomes', 'journeys']),
 *      applyFilter: (values) => { ... },
 *    }
 * }
 * applyFilter should have a function assigned to it that filters the values
 * @returns {*}
 */
function applyFilters(values, filterType, filters) {
    let filtered = values;
    const keys = Object.keys(filters);
    keys.forEach((key) => {
        if (filters[key].appliesTo.has(filterType)) {
            filtered = filters[key].applyFilter(filtered);
        }
    });
    return filtered;
}