import { timestampAtMidnight } from 'utilities/Convenience';


/**
 * 
 * This function should return an object of arrays with the amount of events bucketized by interval
 * 
 * @param {Object[]} data
 * @param {*} isOutcomes 
 * @returns 
 */
export function getDataCounts(data, isOutcomes = true) {
    //get dataCounts from last 7 days
    //get dataCounts from last 4 weeks
    //get dataCounts from last 3 months
    //get dataCounts from last 4 quarters

    const dataCounts = {
        days: getDataCountByInterval(data, 'day', isOutcomes),
        weeks: getDataCountByInterval(data, 'week', isOutcomes),
        months: getDataCountByInterval(data, 'month', isOutcomes),
        quarters: getDataCountByInterval(data, 'quarter', isOutcomes)
    };
    return dataCounts;
}

const dayInSeconds = 86400;
const intervalMap = {
    'day': dayInSeconds,
    'week': dayInSeconds * 7,
    'month': dayInSeconds * 30,
    'quarter': dayInSeconds * 90
};

export function getDataCountByInterval(data, intervalAsString, isOutcomes) {
    if(data === null || data.length === 0) {
        return [];
    }

    const interval = intervalMap[intervalAsString];

    let series;
    if (isOutcomes) {
        series = [].concat(...data.map((item) => item.series));
    } 
    else {
        series = data;
    }
    //data sorted by timestamp reverse order
    series = series.sort((a,b) => b - a);
    const current = timestampAtMidnight();

    //remove timestamps since last midnight
    while (series[0] > current) {
        series.shift();
    }

    return series.reduce((accumulator, sample) => {
        const delta = current - sample;          
        const bucket = Math.floor(delta / interval);      
        const newBuckets = bucket - accumulator.length + 1;         
        for(let i = 0; i < newBuckets; i++) {                       
            accumulator.push(0);
        }
        accumulator[bucket]++;
        return accumulator;
    }, [0]);
}
