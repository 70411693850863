import React, {useContext, useEffect, useState} from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import {navbarBreakPoint, topNavbarBreakpoint} from 'falcon/config';
import TopNavRightSideNavItem from 'components/navbar/top/TopNavRightSideNavItem';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

const NavbarTop = ({
                       MenuItems = NavbarTopDropDownMenus
                   }) => {
    const {
        config: {showBurgerMenu, navbarPosition, navbarCollapsed, customerCode}, setConfig
    } = useContext(AppContext);

    const {pathname} = useLocation();
    const isChat = pathname.includes('chat');

    const [showDropShadow, setShowDropShadow] = useState(false);

    const handleBurgerMenu = () => {
        navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
        (navbarPosition === 'vertical' || navbarPosition === 'combo') && setConfig('showBurgerMenu', !showBurgerMenu);
    };

    const setDropShadow = () => {
        const top = document.documentElement.scrollTop || document.body.scrollTop || window.scrollY;
        if (top > 0) {
            setShowDropShadow(true);
        } else {
            setShowDropShadow(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', setDropShadow);
        return () => window.removeEventListener('scroll', setDropShadow);
    }, []);


    return (<Navbar
            className={classNames('navbar-glass fs--1 navbar-top sticky-kit ms-5', {
                // 'navbar-glass-shadow': showDropShadow
                'navbar-glass-shadow': showDropShadow && !isChat
            })}
            expand={navbarPosition === 'top' || navbarPosition === 'combo' ? topNavbarBreakpoint : true}
        >
            {(customerCode !== '' && (<Navbar.Toggle
                    className={classNames('toggle-icon-wrapper me-md-3 me-2', {
                        'd-lg-none': navbarPosition === 'top',
                        [`d-${navbarBreakPoint}-none`]: navbarPosition === 'vertical' || navbarPosition === 'combo'
                    })}
                    as="div"
                >
                    <button
                        className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
                        onClick={handleBurgerMenu}
                        id="burgerMenu"
                    >
                      <span className="navbar-toggle-icon">
                        <span className="toggle-line"/>
                      </span>
                    </button>
                </Navbar.Toggle>))}

            <Logo at="navbar-top" width={40} id="topLogo"/>

            {navbarPosition === 'top' || navbarPosition === 'combo' ? (<Navbar.Collapse
                    in={navbarCollapsed}
                    className="scrollbar pb-3 pb-lg-0"
                >
                    <Nav navbar>
                        <MenuItems/>
                    </Nav>
                </Navbar.Collapse>) : (customerCode !== '' && null)}

            <TopNavRightSideNavItem/>
        </Navbar>);
};


NavbarTop.propTypes = {
    MenuItems: PropTypes.func,
};

export default NavbarTop;
