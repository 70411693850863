import React from 'react';
import {Button, Card, CloseButton, Col, ProgressBar, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import {faFlagCheckered} from '@fortawesome/free-solid-svg-icons/faFlagCheckered';
import {faHourglassStart} from '@fortawesome/free-solid-svg-icons/faHourglassStart';
import {faLocationDot} from '@fortawesome/free-solid-svg-icons/faLocationDot';
import {faFilter} from '@fortawesome/free-solid-svg-icons/faFilter';
import {NullFunction} from 'utilities/Convenience';
import PreviousCounter from 'utilities/PreviousCounter';


const Step = ({step, index}) => {
    const {
        category,
        funnel,
        outcome,
        action,
        previous,
        deltaAvg,
        deltaMin,
        deltaMax,
        deltaVariation,
        percentOfTotal,
        color,
        count,
        deltas,
        superOutcome,
        delta,
        result,
        timestamp,
        tags,
        platform,
        ...remainder
    } = step;
    if (previous) return null;

    let icon = faFlagCheckered;
    let title = 'Outcome: ' + outcome;
    if (category) {
        icon = faLocationDot;
        title = 'Category: ' + category;
    }
    if (funnel) {
        icon = faFilter;
        title = 'Funnel: ' + funnel;
    }

    let display = [];
    let remainderIndex = 0;
    for (const [key, value] of Object.entries(remainder)) {
        const keyString = key.toString();
        const valueString = value.toString();
        const formattedKey = keyString.charAt(0).toUpperCase() + keyString.slice(1);
        const formattedValue = valueString.charAt(0).toUpperCase() + valueString.slice(1);
        display.push(
            <div key={remainderIndex} className={classNames('fs--1',
                'mb-0',
                `step-${index}-timeline-extra-${remainderIndex}`)}
            >
                <p>{`${formattedKey}: ${formattedValue}`}</p>
            </div>
        );
        remainderIndex++;
    }

    return (
        <div
            id={'step-' + index}
            key={'step-' + index}
            className={classNames('timeline-item', `step-${index}`, 'timeline-item-end')}
        >
            <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
                <FontAwesomeIcon icon={icon} className="fs-1"/>
            </div>
            <Row className={classNames('timeline-item-end')}>
                <Col lg={6} className="timeline-item-time align-items-start" style={{justifyContent: 'flex-start'}}>
                    <Row style={{width: '110%'}}>
                        <Col className="py-0" style={{blockSize: 'fit-content'}}>
                            <p className={classNames('fs--1', 'mb-0', 'fw-semi-bold', `step-${index}-title`)}>
                                {title}
                            </p>
                            <p className={classNames('fs--2', 'mb-0', 'text-600', `step-${index}-elapsed`)}>
                                Elapsed: {deltaAvg}s{count ? ` - Repeats (x${count})` : ''}
                            </p>
                            <p className={classNames('fs--3', 'mb-0', 'text-600', `step-${index}-stats`)}>
                                (Max: {deltaMax}s / Min: {deltaMin}s / Variation: {deltaVariation}%)
                            </p>
                        </Col>
                        <Col lg={4}
                             className={classNames('pt-3', 'col', 'd-flex', 'justify-content-end', 'align-items-start')}
                             style={{minWidth: '110px'}}>
                            <ProgressBar now={percentOfTotal} variant={color} style={{width: '80px', height: 5}}
                                         className={`step-${index}-progress`}/>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <div className="timeline-item-content" style={{marginLeft: '1rem'}}>
                        <div className="timeline-item-card">
                            <p className={classNames('fs--1', 'mb-0', `step-${index}-action`)}>
                                {JSON.stringify(action)}
                            </p>
                            {display}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

Step.propTypes = {
    index: PropTypes.number.isRequired,
    step: PropTypes.shape({
        category: PropTypes.string,
        funnel: PropTypes.string,
        outcome: PropTypes.string,
        previous: PropTypes.array,
        action: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        timestamp: PropTypes.number.isRequired,
        deltaAvg: PropTypes.number.isRequired,
        deltaMin: PropTypes.number.isRequired,
        deltaMax: PropTypes.number.isRequired,
        deltaVariation: PropTypes.number.isRequired,
        percentOfTotal: PropTypes.number.isRequired,
        color: PropTypes.string.isRequired,
        count: PropTypes.number,
        deltas: PropTypes.array,
        delta: PropTypes.number,
        superOutcome: PropTypes.string,
        result: PropTypes.string,
        tags: PropTypes.array,
        platform: PropTypes.object
    }).isRequired,
    timeline: PropTypes.array.isRequired,
    totalTime: PropTypes.number.isRequired
};

export function Round2Decimals(num) {
    const numToRound = (num + Number.EPSILON) * 100;
    const rounded = Math.round(numToRound);
    return rounded / 100;
}

export function Mean(numArray) {
    return numArray.length ? numArray.reduce((s, n) => s + n) / numArray.length : 0;
}

export function Variance(numArray, mean) {
    return numArray.reduce((s, n) => s + ((n - mean) ** 2), 0) / (numArray.length > 1 ? numArray.length - 1 : 1);
}

export function StandardDeviation(numArray) {
    const mean = Mean(numArray);
    const variance = Variance(numArray, mean);
    return Math.sqrt(variance);
}

export function Variation(numArray) {
    const mean = Mean(numArray);
    const variance = Variance(numArray, mean);
    const stddev = Math.sqrt(variance);
    return mean > 0 ? stddev / mean : 0;
}


const Timeline = ({
                      timeline,
                      journey,
                      journeys,
                      outcomes,
                      index = 0,
                      onRemove = NullFunction,
                      onClickOutcome = NullFunction,
                  }) => {
    if (!journey || journey === '' || timeline.length < 1) return null;
    const prefix = 'timeline-' + index.toString();
    const lastStep = timeline.length - 1;
    const firstStep = timeline[0];
    const {previous} = firstStep;
    const totalTime = timeline[lastStep].timestamp - timeline[0].timestamp;

    const deltasAvg = timeline.map((element) => {
        return Mean(element.deltas);
    });
    const deltasMin = timeline.map((element) => Math.min(...element.deltas));
    const deltasMax = timeline.map((element) => Math.max(...element.deltas));
    const deltasVariation = timeline.map((element) => Variation(element.deltas));

    const max = Math.max(...deltasAvg);
    const mean = Mean(deltasAvg);
    const stddev = StandardDeviation(deltasAvg);

    const colors = deltasAvg.map((element) => {
        if (element < mean + stddev) return 'primary';
        if (element < mean + 2 * stddev) return 'warning';
        return 'danger';
    });

    const processedTimeline = timeline.map((element, index) => {
        return {
            ...element,
            deltaAvg: Round2Decimals(deltasAvg[index]),
            deltaMin: Round2Decimals(deltasMin[index]),
            deltaMax: Round2Decimals(deltasMax[index]),
            deltaVariation: Math.round(deltasVariation[index] * 100),
            color: colors[index],
            percentOfTotal: Math.ceil((deltasAvg[index] / max) * 100)
        };
    });

    const filteredJourneys = journeys.filter(aJourney => aJourney.title === journey);
    const extraTitle = filteredJourneys.reduce((p, aJourney) => {
        if (aJourney.extra && Object.keys(aJourney.extra).includes('platform')) {
            const platform = aJourney.extra.platform;
            p = `${platform.softwareVersion}-${platform.deviceModel}-${platform.operatingSystemVersion}`;
        }
        return p;
    }, null);

    const extra = filteredJourneys.reduce((p, aJourney) => {
        if (aJourney.extra && Object.keys(aJourney.extra).includes('platform')) {
            const platform = aJourney.extra.platform;
            p = (
                <div className={classNames('fs--1', 'mb-0', 'timeline-platform')}>
                    <p>{`Software Version: ${platform.softwareVersion}`}</p>
                    <p>{`Device: ${platform.deviceModel}`}</p>
                    <p>{`OS Version: ${platform.operatingSystemVersion}`}</p>
                </div>
            );
        }
        return p;
    }, null);
    const extraTags = filteredJourneys.reduce((p, aJourney) => {
        if (aJourney.tags) {
            const {tags} = aJourney;
            const series = Object.keys(tags);
            if (!series.length) return p;
            if (!tags[series[0]].length) return p;

            p = (
                <div className={classNames('fs--1', 'mb-0', 'timeline-tags')}>
                    <p>{`Variants: ${tags[series[0]].join(', ')}`}</p>
                </div>
            );
        }
        return p;
    }, null);


    const extraJsx = extra || extraTags ? (
        <Col lg={6}>
            <div className="timeline-item-content">
                <div className="timeline-item-card">
                    {extra}
                    {extraTags}
                </div>
            </div>
        </Col>
    ) : null;

    const previousJsx = previous && previous.length > 0 ? (<>
        <p className={classNames('fs--2', 'mb-0', 'text-600', 'timeline-previous-title')}>
            Previous Outcome{previous.length > 1 ? 's' : ''}:
        </p>
        <ul className="ps-0 timeline-previous-list" style={{listStyle: 'none'}}>{
            (() => {
                const filteredOutcomesMap = {};
                outcomes.forEach(outcome => {
                    if (previous.includes(outcome.uuid)) {
                        filteredOutcomesMap[outcome.uuid] = outcome.name;
                    }
                });
                const counts = PreviousCounter(previous);
                const jsxNames = [];
                counts.forEach(step => jsxNames.push(
                    <li key={'previous-' + step.item()} className={classNames('fs--2', 'mb-0', 'text-600')}>
                        <Button
                            target="_blank"
                            variant="link"
                            className={classNames('fs--2', 'mb-0', 'timeline-previous-' + step.item())}
                            onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                onClickOutcome({name: filteredOutcomesMap[step.item()], uuid: step.item()});
                            }}
                        >
                            {filteredOutcomesMap[step.item()]} {step.count() > 1 ? 'x' + step.count().toString() : ''}
                        </Button>

                    </li>
                ));
                return jsxNames;
            })()
        }</ul>
    </>) : null;

    const displayTitle = extraTitle ? extraTitle : journey;

    return (<>
        <Card className={'mb-3 timeline ' + prefix}>
            <FalconComponentCard.Header
                className="bg-light timeline-title"
                noPreview
            >
                <Flex>
                    <div className="timeline-title-common align-self-center" style={{width: '96%'}}>Journey timeline
                        for <span
                            className="truncate-header timeline-title-specific">{displayTitle}</span></div>
                    <div className="flex-shrink-1 align-self-center">
                        <CloseButton
                            className={prefix + '-close'}
                            onClick={() => {
                                document.activeElement.blur();
                                onRemove(journey);
                            }}
                        />
                    </div>
                </Flex>
            </FalconComponentCard.Header>
            <Card.Body className="px-sm-2 px-md-2 px-lg-2 px-xxl-2">
                <div className="timeline-vertical">
                    <div
                        className={classNames('timeline-item', 'timeline-item-end')}
                    >
                        <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
                            <FontAwesomeIcon icon={faHourglassStart} className="fs-1"/>
                        </div>
                        <Row
                            className="timeline-item-end"
                        >
                            <Col lg={6} className="timeline-item-time">
                                <div>
                                    <p className={classNames('fs--1', 'mt-2', 'mb-0', 'fw-semi-bold', 'timeline-starts')}> Start {journey}</p>
                                    {previousJsx}
                                </div>
                            </Col>
                            {extraJsx}
                        </Row>
                    </div>
                    {processedTimeline.map((step, index) => (
                        <Step step={step} key={index} index={index} timeline={timeline} totalTime={totalTime}/>
                    ))}
                </div>
            </Card.Body>
        </Card>
    </>);
};

Timeline.propTypes = {
    timeline: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string,
        funnel: PropTypes.string,
        outcome: PropTypes.string,
        previous: PropTypes.array,
        action: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        timestamp: PropTypes.number.isRequired,
        deltas: PropTypes.arrayOf(PropTypes.number).isRequired
    })).isRequired,
    journey: PropTypes.string.isRequired,
    journeys: PropTypes.array.isRequired,
    outcomes: PropTypes.array.isRequired,
    index: PropTypes.number,
    onRemove: PropTypes.func,
    onClickOutcome: PropTypes.func,
};

export default Timeline;