import React, {useEffect, useRef, useState} from 'react';
import {Accordion, Button, Card, ProgressBar, Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import {deferredCallback, NullFunction, NullScroller} from 'utilities/Convenience';


const getNumberOfJourneys = items =>
    items.reduce((total, item) => total + item.occurrences, 0);

const Journey = ({journey, totalJourneys, isLast, onAdd, onRemove, index, selected, maxPercentage}) => {
    const {id, occurrences, title, extra, num_steps} = journey;
    const percentage = (occurrences / totalJourneys * 100).toFixed(0);
    const displayPercentage = (percentage / maxPercentage * 100).toFixed(0);
    const [checked, setChecked] = useState(selected.includes(title));
    const prefix = 'journey-';
    if (!selected.includes(title) && checked) setChecked(false);

    let displayedTitle = title;
    if (extra && Object.keys(extra).includes('platform')) {
        displayedTitle =
            `${extra.platform.softwareVersion}-${extra.platform.deviceModel}-${extra.platform.operatingSystemVersion} (${title.substring(0, 8)})`;
    }

    return (
        <tr id={`${prefix}${id}`} className={classNames(
            `${prefix}${index}`,
            {'border-bottom border-200': !isLast},
            checked ? 'bg-info-light' : ''
        )}>
            <td>
                <Flex alignItems="left">
                    <h6 className="mb-1 fw-semi-bold" style={{width: '160%'}}>
                        <Button
                            target="_blank"
                            variant="link"
                            size="sm"
                            className={`text-dark ${prefix}${index}-button truncate-title`}
                            onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                const next = !checked;
                                next ? onAdd(title) : onRemove(title);
                                setChecked(next);
                            }}
                        >
                            {displayedTitle}
                        </Button>
                    </h6>
                </Flex>
            </td>
            <td className={`align-middle text-end fw-semi-bold ${prefix}${index}-steps`}>
                {num_steps ? num_steps.toString() : '0'}
            </td>
            <td className={`align-middle text-end fw-semi-bold ${prefix}${index}-occurrences`}>
                {occurrences}
            </td>
            <td className="align-middle pe-card">
                <Flex alignItems="center">
                    <ProgressBar className={`${prefix}${index}-progress`} now={displayPercentage}
                                 style={{width: '80px', height: 5}}/>
                    <div className={`fw-semi-bold ms-3 ${prefix}${index}-percentage`}>{percentage}%</div>
                </Flex>
            </td>
        </tr>
    );
};

Journey.propTypes = {
    totalJourneys: PropTypes.number.isRequired,
    isLast: PropTypes.bool,
    journey: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        occurrences: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        extra: PropTypes.object,
        num_steps: PropTypes.number
    }).isRequired,
    index: PropTypes.number,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    maxPercentage: PropTypes.number.isRequired
};

const Journeys = ({
                      journeys,
                      outcome,
                      onAdd,
                      onRemove,
                      selected,
                      onReselect = NullFunction,
                      analyzing,
                      spinning
                  }) => {
    const bottom = useRef(new NullScroller());
    const topRef = useRef(null);
    const [expanded, setExpanded] = useState('1');
    useEffect(() => {
        const stillSelected = journeys.reduce((previous, item) => {
            if (selected.includes(item.title)) previous.push(item.title);
            return previous;
        }, []);
        if (selected && stillSelected && stillSelected.length !== selected.length) onReselect(stillSelected);
    }, [journeys, onReselect, selected]);
    if (!outcome || outcome.name === '') return null;
    if (journeys.length < 1) {
        return spinning('journeys-spinner');
    }
    const {name, platform} = outcome;
    
    if (platform) {
        let filtered_journeys = [];
        journeys.forEach(element => {
            if (platform === 'Unset' && !element['extra'])
                filtered_journeys.push(element);
            else if (element['extra']) {
                // Google, iP, Amazon, (pattern match these) otherwise use the actual operatingSystemVersion
                let operatingSystemVersion = element['extra']['platform']['operatingSystemVersion'];

                if (operatingSystemVersion.includes('Google')) {
                    operatingSystemVersion = 'Android';
                } else if (element['extra']['platform']['deviceModel'] && element['extra']['platform']['deviceModel'].includes('iP')) {
                    operatingSystemVersion = 'IOS';
                } else if (operatingSystemVersion.includes('Amazon')) {
                    operatingSystemVersion = 'Amazon';
                }

                if (operatingSystemVersion === platform)
                    filtered_journeys.push(element);
            }
        });
        journeys = filtered_journeys;
    }

    const totalJourneys = getNumberOfJourneys(journeys);
    const maxPercentage = journeys[0].occurrences / totalJourneys * 100;
    const noOfJourneys = journeys.length;

    function showJourneys() {
        const numberToShow = 10;

        function mapToJsx(journeyMap, startAt = 0) {
            return journeyMap.map((journey, index) => (
                <Journey
                    journey={journey}
                    totalJourneys={totalJourneys}
                    isLast={index === noOfJourneys - 1}
                    key={journey.id}
                    index={index + startAt}
                    onAdd={(title) =>
                        onAdd(title, deferredCallback(
                            () => bottom.current.scrollIntoView({behavior: 'smooth', block: 'start'})
                        ))
                    }
                    onRemove={onRemove}
                    selected={selected}
                    maxPercentage={maxPercentage}
                />
            ));
        }

        if (journeys.length > numberToShow && !analyzing) {
            const topTen = journeys.slice(0, numberToShow);
            const remaining = journeys.slice(numberToShow);
            const jsxBlob1 = mapToJsx(topTen);
            let width = {};
            if (topRef.current) width = {width: (topRef.current.offsetWidth - 100).toString() + 'px'};

            const jsxBlob2 = (<>
                <tr id="accordion" className="border-bottom border-200">
                    <td className="px-0, py-0">
                        <Accordion activeKey={expanded} onSelect={setExpanded} flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header
                                    className="b7"
                                    style={width}
                                >
                                    Remaining Journeys {'(' + (journeys.length - 10).toString() + ')'}
                                </Accordion.Header>
                            </Accordion.Item>
                        </Accordion>
                    </td>
                </tr>

                {expanded === '0' ? mapToJsx(remaining, numberToShow) : null}
            </>);

            return (<>
                <tbody>{jsxBlob1}{jsxBlob2}</tbody>
            </>);
        }

        return (<>
            <tbody>
            {mapToJsx(journeys)}
            </tbody>
        </>);
    }

    return (
        <Card className="h-lg-100 journeys" ref={topRef}>
            <Card.Header className={'bg-light p-1'}/>
            <Card.Body className="p-0" /*ref={topRef}*/>
                <Table borderless responsive className="mb-0 fs--1" style={{tableLayout: 'fixed'}}>
                    <thead className="bg-light">
                    <tr className="text-900">
                        <th scope="col" className={'journeys-title pt-1'}>Journeys for {platform ? name + "-" + platform : name}</th>
                        <th scope="col" className="text-end pt-1">Length</th>
                        <th scope="col" className="text-end pt-1">Count ({totalJourneys})</th>
                        <th scope="col" className="pe-card text-end pt-1" style={{width: '8rem'}}>
                            Occurrence (%)
                        </th>
                    </tr>
                    </thead>
                    {showJourneys()}
                </Table>
            </Card.Body>
            <Card.Footer className="bg-light py-2"/>
            <span ref={bottom}/>
        </Card>
    );
};

Journeys.propTypes = {
    journeys: PropTypes.arrayOf(Journey.propTypes.journey).isRequired,
    outcome: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    onReselect: PropTypes.func,
    analyzing: PropTypes.bool,
    spinning: PropTypes.func.isRequired
};

export default Journeys;
