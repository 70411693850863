import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {Collapse, Nav} from 'react-bootstrap';
import NavbarVerticalMenuItem from 'components/navbar/vertical/NavbarVerticalMenuItem';
import classNames from 'classnames';
import AppContext from 'context/Context';

const CollapseItems = ({route}) => {
    const {pathname} = useLocation();

    const openCollapse = childrens => {
        const checkLink = children => {
            if (children.to === pathname) {
                return true;
            }
            return (
                Object.prototype.hasOwnProperty.call(children, 'children') &&
                children.children.some(checkLink)
            );
        };
        return childrens.some(checkLink);
    };

    const [open, setOpen] = useState(openCollapse(route.children));

    return (
        <Nav.Item as="li">
            <Nav.Link
                onClick={() => {
                    setOpen(!open);
                }}
                className={classNames('dropdown-indicator cursor-pointer', {
                    'text-500': !route.active
                })}
                aria-expanded={open}
            >
                <NavbarVerticalMenuItem route={route}/>
            </Nav.Link>
            <Collapse in={open} className="collapsable">
                <Nav className="flex-column nav" as="ul">
                    <NavbarVerticalMenu routes={route.children}/>
                </Nav>
            </Collapse>
        </Nav.Item>
    );
};

CollapseItems.propTypes = {
    route: PropTypes.shape({
        name: PropTypes.string.isRequired,
        icon: PropTypes.string,
        children: PropTypes.array.isRequired,
        active: PropTypes.bool
    }).isRequired
};

const NavbarVerticalMenu = ({routes}) => {
    const {
        config: {showBurgerMenu},
        setConfig
    } = useContext(AppContext);

    const handleNavItemClick = () => {
        if (showBurgerMenu) {
            setConfig('showBurgerMenu', !showBurgerMenu);
        }
    };
    return routes.map(route => {
        if (!route.children) {
            return (
                <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
                    <NavLink
                        end={route.exact}
                        to={route.to}
                        state={{open: route.to === '/authentication-modal'}}
                        className={({isActive}) =>
                            isActive ? 'active nav-link nav-link-' + route.name.toLowerCase() : 'nav-link nav-link-' + route.name.toLowerCase()
                        }
                    >
                        <NavbarVerticalMenuItem route={route}/>
                    </NavLink>
                </Nav.Item>
            );
        }
        return <CollapseItems route={route} key={route.name}/>;
    });
};

NavbarVerticalMenu.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
        .isRequired
};

export default NavbarVerticalMenu;
