/**
 * Created by woydzl on 08/31/22.
 */
/**
 * Analyze.spec.js
 *
 * Analyze component for creating filters.
 *
 */
import {Button, Col, Dropdown, FormControl, InputGroup, ButtonGroup, Form} from 'react-bootstrap';
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'chart.js/helpers';
import {hasKey, NullFunction, timestamp} from 'utilities/Convenience';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateTime} from 'luxon';
import {getFiltered} from "utilities/filters/TimeFilter";
import {BiBorderNone} from "react-icons/bi";

const defaultFilter = 'All time';
const dayInSeconds = 86400;
export const deltaMap = {
    'Last day': dayInSeconds,
    'Last 7 days': dayInSeconds * 7,
    'Last 2 weeks': dayInSeconds * 14,
    'Last month': dayInSeconds * 30,
    'Last 2 months': dayInSeconds * 60
};

const mergeFilterParams=(newFilter, merge, filterName, filterMap, setFilterMap) => {
    if (merge) {
        // let replaceFilter = {...filter};
        // Look for existing timeFilter otherwise make empty object
        let replaceFilter = hasKey(filterName, filterMap) ? filterMap[filterName] : {};
        Object.keys(newFilter).forEach((key) => {
            replaceFilter[key] = newFilter[key];
        });
        newFilter = replaceFilter;
    }
    const updatedFilter = {...filterMap};
    updatedFilter[filterName] = newFilter;
    setFilterMap(updatedFilter);
    return updatedFilter;
};

const setFilter = (onFilter, timeframe, filterMap, setFilterMap) => {
    if (timeframe === defaultFilter) {
        const start = timestamp() - 15780000;
        const filterFunction = (values) => values;
        onFilter('time-outcomes', filterFunction, 'outcomes', start);
        onFilter('time-journeys', filterFunction, 'journeys', start);
        return;
    }
    const start = timestamp() - deltaMap[timeframe]
    const newFilterMap = mergeFilterParams({
        name: timeframe,
        start: start
    }, false, 'timeFilter', filterMap, setFilterMap);

    const filterFunction = (outcomes) => getFiltered(outcomes, 'value', newFilterMap['timeFilter']);
    onFilter('time-outcomes', filterFunction, 'outcomes', start);
    const filterFunction2 = (journeys) => getFiltered(journeys, 'occurrences', newFilterMap['timeFilter']);
    onFilter('time-journeys', filterFunction2, 'journeys', start);
}
const setTimestampFilter = (onFilter, range, value, filterMap, setFilterMap) => {
    let parameter = {name: 'Timestamps'};
    parameter[range] = value;
    let start = null;
    if(range === 'start'){
        start = value;
    }
    const newFilterMap = mergeFilterParams(parameter, true, 'timeFilter', filterMap,  setFilterMap);
    const filterFunction = (outcomes) => getFiltered(outcomes, 'value', newFilterMap.timeFilter);
    onFilter('time-outcomes', filterFunction, 'outcomes', start);
    const filterFunction2 = (journeys) => getFiltered(journeys, 'occurrences', newFilterMap.timeFilter);
    onFilter('time-journeys', filterFunction2, 'journeys', start);
};

const GrowingInput = ({
                          label,
                          initialValue,
                          onChange,
                          delayBeforeFiltering = 500}) => {
    const [value, setValue] = useState(initialValue === 0 ? '' : initialValue);
    const changePropagator = debounce(() => onChange(latestValue.current, label), delayBeforeFiltering);
    const latestValue = useRef(initialValue === 0 ? '' : initialValue);

    const changeHandler = evt => {
        const input = evt.target.value;
        setValue(input);

        let value = ((tryValue, formats) => {
            for (const format of formats) {
                let date = DateTime.fromFormat(tryValue, format);
                if (date.isValid) {
                    return date.toUnixInteger();
                }
            }
            return tryValue;
        })(input, [
            'MMMM dd yyyy',
            'MMMM d yyyy',
            'M-d-yyyy',
            'M/d/yyyy',
            'M-d-yy',
            'M/d/yy',
            'D'
        ]);

        let validNumber = /^\d+\.?\d*$/.test(value) ?
            (Math.round(value).toString().length >= 9) : value.toString().length === 0;

        if (changePropagator && validNumber) {
            latestValue.current = value.toString();
            changePropagator();
        }
    };
    return (
        <FormControl
            placeholder={label}
            aria-label={label}
            aria-describedby={label}
            className={'b7 px-2 custom-width timestamp-py ' + label + '-filter-input'}
            htmlSize={value ? value.toString().length + 1 : label.length}
            value={value.toString()}
            onChange={changeHandler}
            onInput={changeHandler}
        />
    );
};

GrowingInput.propTypes = {
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    delayBeforeFiltering: PropTypes.number
};

const Analyze = ({
                     initiallyAnalyzing = false,
                     initialFilterMap,
                     onFilter = NullFunction,
                     onAnalyze = NullFunction,
                     timestampFilterDebounce,
                     initialConsolidateJourneysCheck = true
                 }) => {
    const [selectedFilter, setSelectedFilter] = useState(
        //if initial filter is passed in and includes a time filter which is named, use that
        Object.keys(initialFilterMap).length > 0
            && initialFilterMap.hasOwnProperty('timeFilter')
            && initialFilterMap.timeFilter.hasOwnProperty('name')
                ? initialFilterMap.timeFilter.name : defaultFilter);
    const [filterMap, setFilterMap] = useState(initialFilterMap);
    const [analyzing, setAnalyzing] = useState(
        Object.keys(initialFilterMap).length > 1
            || (Object.keys(initialFilterMap).length === 1 && !initialFilterMap.hasOwnProperty('consolidate-journeys'))
                ? true : initiallyAnalyzing);
    const [consolidateJourneysCheck, setConsolidateJourneysCheck] = useState(initialConsolidateJourneysCheck);

    useEffect(() => {
        completePartialFilters(initialFilterMap);
    }, [initialFilterMap]);

    function completePartialFilters(filterMap) {
        //for each filter in filterMap, if only has name run setFilter
        Object.keys(filterMap).forEach((key) => {
            if (filterMap[key].hasOwnProperty('name') && (!filterMap[key].hasOwnProperty('appliesTo') || !filterMap[key].hasOwnProperty('applyFilter'))) {
                if (filterMap[key].name === 'Timestamps') {
                    if (filterMap[key].hasOwnProperty('start')) {
                        setTimestampFilter(onFilter, 'start', filterMap[key].start, filterMap, setFilterMap);
                    }
                    if (filterMap[key].hasOwnProperty('end')) {
                        setTimestampFilter(onFilter, 'end', filterMap[key].end, filterMap, setFilterMap);
                    }
                } else {
                    setFilter(onFilter, filterMap[key].name, filterMap, setFilterMap);
                }
            }
        });
    }


    return (<>
        <Col xs="auto" className="px-1 analyze-component">
            <label className="analyze-toggle" htmlFor="analyze_component">
                <Form.Check.Input
                    type="checkbox" id="analyze_component"
                    checked={!!analyzing} 

                    onChange={
                        () => {
                            if (analyzing) {
                                setSelectedFilter(defaultFilter);
                                setFilter(onFilter, defaultFilter, filterMap, setFilterMap);
                            }
                            onAnalyze(!analyzing);
                            setAnalyzing(!analyzing);
                        }
                    }
                    className="analyzing-switch"
                />
                <span className="toggle" style={{top: '-2px'}}>
                    <span className="switch"/>
                </span>
            </label>
        </Col>

        {((show) => {
            if (show) {
                return (<>
                    <Col xs="auto" className="px-1" align="center analyze-component-filters">
                        {((show) => {
                            if (show) {
                                const lastDay = 'Last day';
                                const last7Days = 'Last 7 days';
                                const last2Weeks = 'Last 2 weeks';
                                const lastMonth = 'Last month';
                                const last2Months = 'Last 2 months';
                                const timestamps = 'Timestamps';
                                return (
                                    <Dropdown as={ButtonGroup} className="filter-button filter-dropdown">
                                        <Dropdown.Toggle
                                            variant="falcon-default align-middle pt-0 pb-0 b7 filter-dropdown-toggle">
                                            {selectedFilter}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="py-2">
                                            <Dropdown.Item as="button"
                                                           active={selectedFilter === lastDay}
                                                           className="filter-menu-last-day"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               setSelectedFilter(lastDay);
                                                               setFilter(onFilter, lastDay, filterMap, setFilterMap);
                                                           }}
                                            >Last day</Dropdown.Item>
                                            <Dropdown.Item as="button"
                                                           active={selectedFilter === last7Days}
                                                           className="filter-menu-last-7-days"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               setSelectedFilter(last7Days);
                                                               setFilter(onFilter, last7Days, filterMap, setFilterMap);
                                                           }}
                                            >Last 7 days</Dropdown.Item>
                                            <Dropdown.Item as="button"
                                                           active={selectedFilter === last2Weeks}
                                                           className="filter-menu-last-2-weeks"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               setSelectedFilter(last2Weeks);
                                                               setFilter(onFilter, last2Weeks, filterMap, setFilterMap);
                                                           }}
                                            >Last 2 weeks</Dropdown.Item>
                                            <Dropdown.Item as="button"
                                                           active={selectedFilter === lastMonth}
                                                           className="filter-menu-last-month"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               setSelectedFilter(lastMonth);
                                                               setFilter(onFilter, lastMonth, filterMap, setFilterMap);
                                                           }}
                                            >Last month</Dropdown.Item>
                                            <Dropdown.Item as="button"
                                                           active={selectedFilter === last2Months}
                                                           className="filter-menu-last-2-months"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               setSelectedFilter(last2Months);
                                                               setFilter(onFilter, last2Months, filterMap, setFilterMap);
                                                           }}
                                            >Last 2 months</Dropdown.Item>
                                            <Dropdown.Divider/>
                                            <Dropdown.Item as="button"
                                                           active={selectedFilter === timestamps}
                                                           className="filter-menu-timestamps"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               setSelectedFilter(timestamps);
                                                           }}
                                            >Timestamps</Dropdown.Item>
                                            <Dropdown.Divider/>
                                            <Dropdown.Item as="button"
                                                           active={selectedFilter === defaultFilter}
                                                           className="filter-menu-all-time"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               setSelectedFilter(defaultFilter);
                                                               setFilter(onFilter, defaultFilter, filterMap, setFilterMap);
                                                           }}
                                            >{defaultFilter}</Dropdown.Item>
                                        </Dropdown.Menu>
                                        {((show) => {
                                            return show ?
                                                (<Button
                                                    variant="falcon-default align-middle pt-0 pb-0 px-1 b7 filter-dropdown-cancel"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedFilter(defaultFilter);
                                                        setFilter(onFilter, defaultFilter, filterMap, setFilterMap);
                                                    }}
                                                ><FontAwesomeIcon icon="fa-xmark" className="align-middle"/></Button>) :
                                                null;
                                        })(selectedFilter !== defaultFilter)}
                                    </Dropdown>);
                            }
                            return null;
                        })(selectedFilter !== 'Timestamps')}
                        {((show) => {
                            if (show) {
                                return (<>
                                    <InputGroup size="sm" className="b7 mt-1 flex-grow-0 timestamp-control w-auto">
                                        <InputGroup.Text className="b7 timestamp-py" id="start">start</InputGroup.Text>
                                        <GrowingInput
                                            label="start"
                                            initialValue={hasKey('start', filterMap.timeFilter) ? Number(filterMap.timeFilter.start) : 0}
                                            onChange={(value, range) => {
                                                setTimestampFilter(onFilter, range, value, filterMap, setFilterMap);
                                            }}
                                            delayBeforeFiltering={timestampFilterDebounce}
                                        />
                                        <div className="b7 mr-0 align-middle input-group-text timestamp-py"
                                             style={{marginLeft: -1}}>to:
                                        </div>
                                        <GrowingInput
                                            label="end"
                                            initialValue={hasKey('end', filterMap.timeFilter) ? Number(filterMap.timeFilter.end) : 0}
                                            onChange={(value, range) => {
                                                setTimestampFilter(onFilter, range, value, filterMap, setFilterMap);
                                            }}
                                            delayBeforeFiltering={timestampFilterDebounce}
                                        />
                                        <InputGroup.Text className="b7 timestamp-py" id="end">end</InputGroup.Text>
                                        <Button
                                            variant=""
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedFilter(defaultFilter);
                                                setFilter(onFilter, defaultFilter, filterMap, setFilterMap);
                                            }}
                                            className="align-middle px-1 b7 timestamp-py input-group-text timestamp-close-button"
                                        ><FontAwesomeIcon icon="fa-xmark" className="align-middle"/></Button>
                                    </InputGroup>
                                </>);
                            }
                            return null;
                        })(selectedFilter === 'Timestamps')}
                    </Col>
                    {/*<Col>*/}
                    {/*    <ToggleButton*/}
                    {/*        className="consolidate-journeys-toggle vertical-align mt-0 mb-0"*/}
                    {/*        size="sm"*/}
                    {/*        id="consolidate-journeys-toggle-check"*/}
                    {/*        type="checkbox"*/}
                    {/*        variant="outline-primary"*/}
                    {/*        checked={consolidateJourneysCheck}*/}
                    {/*        onChange={(e) => {*/}
                    {/*            setConsolidateJourneysCheck(e.currentTarget.checked);*/}
                    {/*            if (e.currentTarget.checked) {*/}
                    {/*                const filterFunction = (journeys) => consolidateJourneys(journeys);*/}
                    {/*                onFilter('consolidate-journeys', filterFunction, 'journeys');*/}
                    {/*            }*/}
                    {/*            else {*/}
                    {/*                onFilter('consolidate-journeys', (journeys) => journeys, 'journeys');*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Consolidate Journeys*/}
                    {/*    </ToggleButton>*/}
                    {/*</Col>*/}
                </>);
            }
            return null;
        })(analyzing)}
    </>);
};

Analyze.propTypes = {
    initialFilterMap: PropTypes.object,
    onFilter: PropTypes.func,
    onAnalyze: PropTypes.func,
    timestampFilterDebounce: PropTypes.number
};

Analyze.defaultProps = {
    initialFilterMap: {}
  };

export default Analyze;
