import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext} from 'react';
import {Offcanvas, ButtonGroup} from 'react-bootstrap';
import defaultModeImg from 'assets/img/generic/falcon-mode-default.jpg';
import darkModeImg from 'assets/img/generic/falcon-mode-dark.jpg';
import AppContext from 'context/Context';
import RadioItem from 'components/settings-panel/RadioItem';
import PropTypes from 'prop-types';

const SettingsPanel = ({onExited, onEntered}) => {
    const {
        config: {
            isDark,
          showSettingPanel
        },
        setConfig
    } = useContext(AppContext);

    return (
        <Offcanvas
            onEntered={onEntered}
            onExited={onExited}
            show={showSettingPanel}
            onHide={() => setConfig('showSettingPanel', false)}
            placement="end"
            style={{maxWidth: '22rem'}}
            className="border-0 settings-panel"
        >
            <Offcanvas.Header
                closeButton
                closeVariant="white"
                className="bg-shape settings-panel-header"
            >
                <Offcanvas.Title as="div" className="py-1 flex-grow-1 light">
                    <h5 className="text-white settings-panel-title">
                        <FontAwesomeIcon icon="palette" className="me-2 fs-0"/>
                        Settings
                    </h5>
                    <p className="mb-0 fs--1 text-white opacity-75">
                        Set your own customized style
                    </p>
                </Offcanvas.Title>
            </Offcanvas.Header>
            {/* <ScrollBarCustom> */}
            <Offcanvas.Body className="scrollbar settings-panel-body">
                <h5 className="fs-0">Color Scheme</h5>
                <p className="fs--1">Choose the perfect color mode for your preference.</p>

                <ButtonGroup className="btn-group-navbar-style">
                    <RadioItem
                        name="theme-mode"
                        label="light"
                        active={!isDark}
                        onChange={({target}) => setConfig('isDark', false)}
                        image={defaultModeImg}
                        className={'settings-light-mode'}
                    />
                    <RadioItem
                        name="theme-mode"
                        label="dark"
                        active={isDark}
                        onChange={({target}) => setConfig('isDark', true)}
                        image={darkModeImg}
                        className={'settings-dark-mode'}
                    />
                </ButtonGroup>
            </Offcanvas.Body>
            {/* </ScrollBarCustom> */}
        </Offcanvas>
    );
};


SettingsPanel.propTypes = {
    onEntered: PropTypes.func,
    onExited: PropTypes.func
};

export default SettingsPanel;
