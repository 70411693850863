const ApiBase = require('./api_base').default;

class bucketsApi extends ApiBase {
    constructor(apiUrl) {
        let props = {
            name: 'ApiBuckets',
            url: 'buckets',
            apiUrl: apiUrl,
            authenticated: true
        };
        super(props);
    }

    params(data) {
        const {outcomeHash, includeSteps, since} = data;
        let params = {outcomeHash: outcomeHash};
        if (includeSteps) params.includeSteps = includeSteps;
        if (since) params.since = since;
        return params;
    }
}

function BucketsApi(apiUrl) {
    return new bucketsApi(apiUrl);
}

export default BucketsApi;