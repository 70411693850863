import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import AppContext from 'context/Context';
import Gravatar from 'utilities/Gravatar';

const ProfileDropdown = () => {
    const {
        setConfig
    } = useContext(AppContext);
  return (
    <Dropdown navbar={true} as="li" className={'profile-dropdown'}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link profile-toggle"
      >
        <Gravatar className={'avatar avatar-xl rounded-circle profile'}/>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
              as={Link} to="/authentication/card/logout"
              className={'profile-logout'}
              onClick={()=>{
                  setConfig('customerCode', '');
                  setConfig('showBurgerMenu', false);
              }}
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
