import React from 'react';
import { Card } from 'react-bootstrap';

const InvalidCustomerCode = () => (
    <Card className="text-center h-100">
        <Card.Body className="p-5">
            <div className="display-1 text-300 fs-error">500</div>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                Whoops, invalid customer code!
            </p>
            <hr />
            <p className="wrong-code-instructions">
                Please logout, and enter correct customer code.
                If this problem persists,
                <a href="mailto:support@xenonlab.ai" className="ms-1">
                    contact us
                </a>
                .
            </p>
        </Card.Body>
    </Card>
);

export default InvalidCustomerCode;