import img1 from 'assets/img/generic/4.jpg';
import img2 from 'assets/img/generic/5.jpg';
import img3 from 'assets/img/gallery/4.jpg';
import img4 from 'assets/img/gallery/5.jpg';
import img5 from 'assets/img/gallery/3.jpg';

let gallery = [img1, img2, img3, img4, img5];

export default gallery;

