import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {Sparklines, SparklinesLine} from 'react-sparklines';
import {getDataCountByInterval} from 'utilities/CollectingOutcomeCounts';
import {isEqual} from 'lodash';

const Sparkchart = ({
        enabled = true,
        sparkData,
        lineColor,
        areaColor,
        hidden = false,
        height = '40px',
        width = '100px',
        classPrefix,
        Displayer = DisplayComponent,
        dataCounts,
        intervalSelector = 'days',
        showAbsoluteSparkData
    }) => {

    const timeMap = {
        days: 30,
        weeks: 12,
        months: 6,
        quarters: 4
    };

    const intervalLimit = timeMap[intervalSelector];

    const [counts, setCounts] = useState([]); 
    const [mean, setMean] = useState(0);
    const [currentValue, setCurrentValue] = useState(0);
    const [recentTrend, setRecentTrend] = useState('');
    const [previousDataLen, setPreviousDataLen] = useState(0);

    useMemo(() => {
        if (!enabled || !dataCounts || !('days' in dataCounts) || dataCounts.length === 0) {
            return;
        }

        //Normalizing data if required
        const currentLength = sparkData.length;
        if (currentLength === previousDataLen) return;
        setPreviousDataLen(currentLength);
        let bucketSeries = getDataCountByInterval(sparkData, 'day', false);        
        if (showAbsoluteSparkData) {
             // create a copy of bucketSeries to avoid mutating bucketSeries before tooltip calculations
            setCounts([...bucketSeries].reverse()); 
        } else {
            let newcounts = getNormalizedDataCount(bucketSeries, dataCounts.days, dataCounts.days[0]);
            setCounts(newcounts.reverse());
        }

        // Used for tooltip
        setMean(calculateMean(bucketSeries));
        setCurrentValue(bucketSeries[0]); // set with first item in bucketSeries since bucketSeries not reversed but normalizedCounts is reversed
        if (bucketSeries.length > 2) {
            if (bucketSeries[0] > bucketSeries[2]) {
                setRecentTrend('up');
            } 
            else {
                setRecentTrend('down');
            }
        }
        else {
            setRecentTrend('needs more data');
        }
    }, [enabled, dataCounts, sparkData, previousDataLen, showAbsoluteSparkData]);

    if (!enabled) return null;

    const thisClass = classPrefix + '-sparkchart';

    if(counts.length > 1) {
        return (
            <span className={thisClass} hidden={hidden}>
                <Displayer 
                    classPrefix={thisClass}
                    intervalData={counts} 
                    meanData={mean} 
                    currentValue={currentValue}
                    recentTrend={recentTrend}
                    sparklinesStyle={{height: height, width: width}} 
                    color={lineColor} 
                    sparklinesLinesStyle={{stroke: lineColor, fill: areaColor}} 
                    limit={intervalLimit}
                />
            </span>
        );
    }
    return null;
};

function getNormalizedDataCount(outcomeCounts, totalOutcomeCounts, totalOutcomeCountsStandard) {
    //calculate multipliers
    let normalizedOutcomeCounts = [];
    totalOutcomeCountsStandard = totalOutcomeCountsStandard > 0 ? totalOutcomeCountsStandard : 1;
    const smallestLength = outcomeCounts.length > totalOutcomeCounts.length ? totalOutcomeCounts.length : outcomeCounts.length;
    for (let i = 0; i < smallestLength; i++) {
        const outcomeCount = outcomeCounts[i];
        const totalOutcomeCount = totalOutcomeCounts[i] > 0 ? totalOutcomeCounts[i] : 1;
        const multiplier = totalOutcomeCountsStandard / totalOutcomeCount;
        normalizedOutcomeCounts.push(outcomeCount * multiplier);
    }

    //get normalized outcome array after multiplication
    return normalizedOutcomeCounts;
}

Sparkchart.propTypes = {
    enabled: PropTypes.bool,
    sparkData: PropTypes.array.isRequired,
    lineColor: PropTypes.string.isRequired,
    areaColor: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    classPrefix: PropTypes.string.isRequired,
    height: PropTypes.string,
    width: PropTypes.string,
    displayer: PropTypes.object,
    dataCounts: PropTypes.object.isRequired,
    intervalSelector: PropTypes.string
};

export default Sparkchart;

export const DisplayComponent = ({
    classPrefix,
    intervalData,
    meanData,
    currentValue,
    recentTrend,
    sparklinesStyle,
    color,
    sparklinesLinesStyle,
    limit
}) => {

    return (
        <OverlayTrigger
            placement="right"
            overlay={<Tooltip style={{ position: 'fixed' }}>
                <p className={ classPrefix + '-mean' }>Average: { meanData }</p>
                <p className={ classPrefix + '-current-value' }>Current Value: { currentValue }</p>
                <p className={ classPrefix + '-trend' }>Trending {recentTrend}</p>
            </Tooltip>}
        >
            <span className={ classPrefix }>
                <Sparklines data={intervalData} style={sparklinesStyle} limit={limit}>
                    <SparklinesLine color={color} style={sparklinesLinesStyle} />
                </Sparklines>
            </span>
        </OverlayTrigger>
    );
};

DisplayComponent.propTypes = {
    classPrefix: PropTypes.string.isRequired,
    intervalData: PropTypes.array.isRequired,
    meanData: PropTypes.number.isRequired,
    currentValue: PropTypes.number.isRequired,
    recentTrend: PropTypes.string.isRequired,
    sparklinesStyle: PropTypes.shape({
        height: PropTypes.string.isRequired,
        width: PropTypes.string.isRequired
    }),
    color: PropTypes.string.isRequired,
    sparklinesLinesStyle: PropTypes.shape({
        stroke: PropTypes.string.isRequired,
        fill: PropTypes.string.isRequired
    }),
    limit: PropTypes.number.isRequired
};

function calculateMean(data) {
    const sum = data.reduce((a,b) => a + b, 0);
    const mean = sum / data.length || 0;
    return Math.trunc(mean);
}