/**
 * Created by woydzl on 10/08/24.
 */
/**
 * JsonDownload.js
 *
 * Component for downloading a json file.
 *
 */
import React, {useState} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {BsFiletypeJson} from "react-icons/bs";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {arrayFrom, serialApiCalls} from 'components/common/CsvDownload';
import {defaultProps as commonDefaultProps, propTypes as commonPropTypes} from "react-csv/src/metaProps";
import {CSVLink} from "react-csv";

function localBuildURI(data, uFEFF, headers, separator, enclosingCharacter) {
    const type = 'text/json';
    const blob = new Blob([JSON.stringify(data)], {type: "text/json"});
    const dataURI = `data:${type};charset=utf-8,${JSON.stringify(data)}`;

    const URL = window.URL || window.webkitURL;

    return (typeof URL.createObjectURL === 'undefined')
        ? dataURI
        : URL.createObjectURL(blob);
}

class JsonLink extends CSVLink{
    buildURI() {
        return localBuildURI(...arguments);
    }

    /**
     * In IE11 this method will trigger the file download
     */
    handleLegacy(event, isAsync = false) {
        // If this browser is IE 11, it does not support the `download` attribute
        if (window.navigator.msSaveOrOpenBlob) {
            // Stop the click propagation
            event.preventDefault();

            const {
                data,
                headers,
                separator,
                filename,
                enclosingCharacter,
                uFEFF
            } = this.props;

            const jsonData = isAsync && typeof data === 'function' ? data() : data;
            let blob = new Blob([JSON.stringify(jsonData)], {type: "text/json"});
            window.navigator.msSaveBlob(blob, filename);

            return false;
        }
    }
}

// class JsonLink extends React.Component {
//     static defaultProps = commonDefaultProps;
//     static propTypes = commonPropTypes;
//
//     constructor(props) {
//         super(props);
//         this.buildURI = this.buildURI.bind(this);
//         this.href = null;
//     }
//
//     buildURI() {
//         return localBuildURI(...arguments);
//     }
//
//     /**
//      * In IE11 this method will trigger the file download
//      */
//     handleLegacy(event, isAsync = false) {
//         // If this browser is IE 11, it does not support the `download` attribute
//         if (window.navigator.msSaveOrOpenBlob) {
//             // Stop the click propagation
//             event.preventDefault();
//
//             const {
//                 data,
//                 headers,
//                 separator,
//                 filename,
//                 enclosingCharacter,
//                 uFEFF
//             } = this.props;
//
//             const jsonData = isAsync && typeof data === 'function' ? data() : data;
//             let blob = new Blob([JSON.stringify(jsonData)], {type: "text/json"});
//             window.navigator.msSaveBlob(blob, filename);
//
//             return false;
//         }
//     }
//
//     handleAsyncClick(event) {
//         event.preventDefault();
//         const done = proceed => {
//             if (proceed === false) {
//                 return;
//             }
//             let link = document.createElement('a');
//             link.href = this.href;
//             const {filename} = this.props;
//             link.download = filename;
//             link.click();
//             this.handleLegacy(event, true);
//         };
//
//         this.props.onClick(event, done);
//     }
//
//     handleSyncClick(event) {
//         const stopEvent = this.props.onClick(event) === false;
//         if (stopEvent) {
//             event.preventDefault();
//             return;
//         }
//         this.handleLegacy(event);
//     }
//
//     handleClick() {
//         return event => {
//             if (typeof this.props.onClick === 'function') {
//                 return this.props.asyncOnClick
//                     ? this.handleAsyncClick(event)
//                     : this.handleSyncClick(event);
//             }
//             this.handleLegacy(event);
//         };
//     }
//
//     render() {
//         const {
//             data,
//             headers,
//             separator,
//             filename,
//             uFEFF,
//             children,
//             onClick,
//             asyncOnClick,
//             enclosingCharacter,
//             ...rest
//         } = this.props;
//
//         const isNodeEnvironment = typeof window === 'undefined';
//         const href = isNodeEnvironment ? '' : this.buildURI(data, uFEFF, headers, separator, enclosingCharacter)
//         this.href = href
//
//         return (
//             <a
//                 download={filename}
//                 {...rest}
//                 ref={link => (this.link = link)}
//                 target="_self"
//                 href={href}
//                 onClick={this.handleClick()}
//             >
//                 {children}
//             </a>
//         );
//     }
// }

const JsonDownload = ({
                          headers,
                          getJsonData,
                          className = '',
                          filename = 'data.json',
                          style = {},
                          log = console.log
                      }) => {
    const [loading, setLoading] = useState(false);
    const [jsonData, setJsonData] = useState([]);

    return (<OverlayTrigger
        placement="top"
        animation={null}
        overlay={<Tooltip style={{position: 'fixed'}}>
            <span className="csv-tooltip">Download JSON</span>
        </Tooltip>}>
        {({ref, ...triggerHandler}) => (
            <JsonLink
                ref={ref}
                {...triggerHandler}
                data={jsonData}
                headers={headers}
                asyncOnClick={true}
                onClick={async (event, done) => {
                    setJsonData([]);
                    setLoading(true);
                    try {
                        if (Array.isArray(getJsonData)) {
                            const jsonDataArray = await arrayFrom(serialApiCalls(getJsonData));
                            const combinedJsonData = jsonDataArray.flat(1);
                            setJsonData(combinedJsonData);
                        } else {
                            const newJsonData = await getJsonData();
                            setJsonData(newJsonData);
                        }
                        setLoading(false);
                        done(true);
                    } catch (e) {
                        log('Error getting JSON data:', e);
                        setLoading(false);
                        done(false);
                    }
                }}
                className={classNames(className, 'json-download')}
                filename={filename}
            >
                {loading ?
                    <Spinner className="json-spinner" animation="border" size="xs" style={style}/> :
                    <BsFiletypeJson className="json-link-icon" style={style}/>}
            </JsonLink>)}
    </OverlayTrigger>);
};

JsonDownload.propTypes = {
    headers: PropTypes.array.isRequired,
    getJsonData: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
    className: PropTypes.string,
    filename: PropTypes.string,
    style: PropTypes.object,
    log: PropTypes.func
};


export default JsonDownload;
