const ApiBase = require('./api_base').default;

class outcomesApi extends ApiBase {
    constructor(apiUrl) {
        let props = {
            name: 'ApiOutcomes',
            url: 'outcomes',
            apiUrl: apiUrl,
            authenticated: true
        };
        super(props);
    }
}

function OutcomesApi(apiUrl) {
    return new outcomesApi(apiUrl);
}

export default OutcomesApi;