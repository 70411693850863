import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import AppContext from 'context/Context';

import LastEventsApi from 'api/last_events';
import classNames from 'classnames';

const demoLastEvents = [
    {
        id: 1, value: 10, name: 'Email Opened', color: 'primary', uuid: '1'
    },
    {
        id: 2, value: 4, name: 'Trial Signup', color: 'info', uuid: '2',
    },
    {
        id: 3, value: 1, name: 'Subscribe', color: '300', uuid: '3'
    }
];

const Onboarding = ({
                        lastEventsApi_ = LastEventsApi,
                        seedEvents = [],
                        errorLog_ = console.log,
                    }) => {
    const [events, setEvents] = useState(seedEvents);
    const [mode, setMode] = useState('customer');
    const navigate = useNavigate();

    const {
        config: {
            customerCode,
            apiUrl
        }
    } = useContext(AppContext);


    useEffect(() => {
        function setupDemo() {
            if (mode !== 'demo') {
                setEvents(demoLastEvents);
                setMode('demo');
            }
        }

        function getLastEvents(data) {
            lastEventsApi_(apiUrl).fetch({data})
                .then(response => {
                    setEvents(response);
                    setMode('customer');
                })
                .catch(error => {
                    errorLog_(error);
                });
        }

        if (customerCode === '') {
            navigate('/');
            return;
        }

        if (customerCode === 'demo') {
            setupDemo();
            return;
        }

        let data = {token: customerCode};
        if (events.length < 1) {
            getLastEvents(data);
        }

    }, [
        navigate, customerCode, mode, apiUrl,
        lastEventsApi_, errorLog_,
        events
    ]);


    if (customerCode === '') return null;
    let sorted = events;
    sorted.sort((a, b) => (b.timestamp - a.timestamp));
    return (
        <div className={'onboarding-dashboard'}>
            <Row className="g-3 mb-3">
                <Col>
                    <Card className="last-recorded-events h-md-100">
                        <Card.Header className="pb-0">
                            <Row className="align-items-center">
                                <Col xs="auto" className="align-middle">
                                    <h6 className="h6-vertical-align mt-0 mb-0">Last Recorded Events</h6>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="pt-0">
                            <Row className="justify-content-between g-0">
                                <Col xs={10} sm={10} className="pe-2">
                                    {sorted.map((value, index) => (
                                        <Row
                                            className={classNames(
                                                `event-${index}`,
                                                'fw-semi-bold',
                                                'fs--2',
                                                `${index === 0 && 'mt-2'}`,
                                                `${index !== 0 && 'mt-1'}`,
                                                'pe-1',
                                                'px-1',
                                                'me-1',
                                                'ms-0'
                                            )}
                                            key={index}
                                        >
                                            {(() => {
                                                delete value.platform;
                                                delete value.tags;
                                                return JSON.stringify(Object.values(value));
                                            })()
                                            }
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
Onboarding.propTypes = {
    lastEventsApi_: PropTypes.func,
    seedEvents: PropTypes.array,
    errorLog_: PropTypes.func,
};

export default Onboarding;
