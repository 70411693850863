import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row, Spinner} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import * as echarts from 'echarts/core';
import {demoOutcomes} from '../default/index';
import {colors, grays, getColor} from 'helpers/utils';
import AppContext from 'context/Context';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {consolidateForCategories} from 'utilities/ConsolidateForCategories';
import {getDataCounts} from 'utilities/CollectingOutcomeCounts';

import {BarChart, LineChart} from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    ToolboxComponent,
    LegendComponent
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import apiRetrier from 'api/api_retrier';
import OutcomesApi from 'api/outcomes';
import {NullFunction} from 'utilities/Convenience';

echarts.use([
    BarChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    CanvasRenderer,
    LegendComponent
]);

export const tooltipFormatter = params => {
    let tooltipItem = '';
    if (Array.isArray(params)) {
        params?.forEach(el => {
            tooltipItem =
                tooltipItem +
                `<div class='ms-1'> 
                    <h6 class="text-700">
                    <div class="dot me-1 fs--2 d-inline-block" style="background-color:${
                                el.borderColor ? el.borderColor : el.color
                            }"></div>
                    ${el.seriesName} : ${el.value}
                    </h6>
                </div>`;
        });
        return `<div>
                <p class='mb-2 text-600'>
                    ${params[0].axisValue}
                </p>
                ${tooltipItem}
          </div>`;
    }
    return '';
};

const weeks = [
    'Week(-6)',
    'Week(-5)',
    'Week(-4)',
    'Week(-3)',
    'Week(-2)',
    'Week(-1)',
    'Week(0)'
];

export function getWeeklySeries(outcomes) {
    const outcomesPerWeek = getDataCounts(outcomes).weeks;
    const outcomeSeries = new Array(weeks.length).fill(0);
    const maxLength = weeks.length > outcomesPerWeek.length ? outcomesPerWeek.length : weeks.length;

    for (let i = 0; i < maxLength; i++) {
        outcomeSeries[i] = outcomesPerWeek[i];
    }
    outcomeSeries.reverse();
    return outcomeSeries;
}

const getOption = (outcomes) => {
    const start = Date.now();
    const consolidated = consolidateForCategories(outcomes, false);
    const outcomeSeries = getWeeklySeries(consolidated);
    const numberOfOutcomes = consolidated.length > 5 ? 5 : consolidated.length;
    let series = [];
    let legend = [];

    for (let i = 0; i < numberOfOutcomes; i++) {
        const unformatted = consolidated[i];
        const barSeries = getWeeklySeries([unformatted]);
        series.push({
            name: unformatted.name,
            type: 'bar',
            yAxisIndex: 0,
            data: barSeries,
            itemStyle: {
                color: colors[i],
                borderRadius: [3, 3, 0, 0]
            }
        });
        legend.push(unformatted.name);
    }
    legend.push('Total Outcomes');
    series.push({
        name: 'Total Outcomes',
        type: 'line',
        yAxisIndex: 1,
        data: outcomeSeries,
        lineStyle: {
            color: grays[1000]
        },
        itemStyle: {
            color: getColor('white'),
            borderColor: grays[1000],
            borderWidth: 2
        },
        symbol: 'circle',
        symbolSize: 10
    });
    return ({
        title: {
            textStyle: {
                color: getColor('gray-700')
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: getColor('500')
                },
                label: {
                    show: true,
                    backgroundColor: getColor('600'),
                    color: getColor('100')
                }
            },
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-300'),
            textStyle: {color: getColor('gray-1100')},
            borderWidth: 1,
            transitionDuration: 0,
            formatter: tooltipFormatter
        },
        toolbox: {
            bottom: 0,
            orient: 'vertical',
            feature: {
                saveAsImage: {show: true}
            },
            iconStyle: {
                borderColor: getColor('700'),
                borderWidth: 1
            },

            emphasis: {
                iconStyle: {
                    textFill: getColor('600')
                }
            }
        },
        legend: {
            top: 0,
            data: legend,
            type: 'scroll',
            textStyle: {
                color: getColor('gray-700')
            }
        },
        calculable: true,
        xAxis: [
            {
                type: 'category',
                data: weeks,
                splitLine: {show: false},
                axisLabel: {
                    color: getColor('gray-600')
                },
                axisLine: {
                    lineStyle: {
                        color: getColor('gray-400')
                    }
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: value => ((value > 1000) ? value / 1000 + 'k' : value),
                    color: getColor('gray-600')
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: getColor('gray-200')
                    }
                }
            },
            {
                type: 'value',
                axisLabel: {
                    color: getColor('600'),
                    formatter:  value => ((value > 1000) ? value / 1000 + 'k' : value)
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: getColor('200')
                    }
                }
            }
        ],
        series:  series,
        grid: {
            top: 40,
            bottom: 30,
            left: 5,
            right: 5,
            containLabel: true
        }
    });
};

const defaultSpinning = (identifier) => {
    return (<div key={identifier} className={'d-flex justify-content-center ' + identifier}>
        <Spinner animation="border" role="status" style={{width: '5rem', height: '5rem'}}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>);
};

const setDefaultSpinning = () => (defaultSpinning);
const setNoSpinning = () => (NullFunction);

const Landing = ({
                     outcomesApi_ = OutcomesApi,
                     outcomes_ = [],
                     maxApiRetry_ = -1,
                     errorLog_ = console.log,
                     spinning_ = setDefaultSpinning
                 }) => {
    const [outcomes, setOutcomes] = useState(outcomes_);
    const [chartOptions, setChartOptions] = useState({});
    const [spinning, setSpinning] = useState(spinning_);
    const navigate = useNavigate();
    const maxApiTime = 180000; //3 minutes

    const {
        config: {
            customerCode,
            apiUrl
        }
    } = useContext(AppContext);

    useMemo(()=>{
        setChartOptions(getOption(outcomes));
    }, [outcomes]);


    useEffect(() => {
        if (customerCode === '') {
            navigate('/');
            return;
        }

        if (customerCode === 'demo' && !outcomes.length) {
            setOutcomes(demoOutcomes);
            setSpinning(setNoSpinning);
            return;
        }

        function getOutcomes(data) {
            const start = Date.now();
            function handle(response) {
                setOutcomes(response);
            }

            apiRetrier(() => outcomesApi_(apiUrl).fetch({data}), maxApiRetry_, maxApiTime)
                .then(response => {
                    handle(response);
                })
                .catch(error => {
                    errorLog_(error);
                    const {noNet, invalidCode} = error;
                    if (invalidCode) {
                        navigate('/errors/invalid-code');
                    }
                    if (noNet) {
                        navigate('/errors/no-net');
                    }
                });
        }

        let data = {token: customerCode};
        if (outcomes.length < 1) {
            getOutcomes(data);
            setSpinning(setNoSpinning);
        }

    }, [navigate, customerCode, apiUrl, errorLog_, outcomes, maxApiRetry_, outcomesApi_]);

    if (customerCode === '') return null;
    return (
        <div className={'onboarding-dashboard'}>
            <Row className="g-3 mb-3">
                <Col>
                    <Card className="last-recorded-events h-md-100">
                        <Card.Header className="pb-0">
                            <Row className="align-items-center">
                                <Col xs="auto" className="align-middle">
                                    <h6 className="h6-vertical-align mt-0 mb-0">Discover-r</h6>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className="pt-0">
                            {outcomes.length ? (<ReactEChartsCore
                                className={'discover-r-chart'}
                                echarts={echarts}
                                option={chartOptions}
                                style={{height: '28.75rem'}}/>) :
                                spinning('discover-r-spinner')
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
Landing.propTypes = {
    lastEventsApi_: PropTypes.func,
    seedEvents: PropTypes.array,
    errorLog_: PropTypes.func,
};

export default Landing;
