/**
 * Created by woydzl on 08/25/22.
 */
/**
 * Convenience.js
 *
 * several nice to have utilities that have no better place to go.
 *
 */
export function hasKey(name, theMap){
    if (!theMap) return false;
    return Object.keys(theMap).includes(name);
}

export function timestamp(){return (new Date()).getTime()/1000;}

export function timestampAtMidnight() {
    // Get current date object
    const currentDate = new Date();

    // Set time to midnight
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    // Get time in seconds from epoch
    const epochSeconds = Math.floor(currentDate.getTime() / 1000);

    // Output result
    // console.log(epochSeconds);
    return epochSeconds;
}

export function deferredCallback(init_ = null) {
    let resolve = init_;
    return function (init = null) {
        if (init && !resolve) resolve = init;
        else if (resolve) {
            let cached = resolve;
            resolve = null;
            cached();
        }
    };
}

export class NullScroller {
    scrollIntoView(){
    }
}

export function NullFunction() {return null;}