export const version = '3.3.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const setUrl = (envValue)=> {
  let host = 'api.xenonview.com';
  if (envValue && envValue !== '' ) host = envValue;
  return `https://${host}`;
};
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  customerCode: '',
  apiUrl: setUrl(process.env.REACT_APP_APIURL)
  };

const config = {
  version, navbarBreakPoint, topNavbarBreakpoint, settings
};

export default config;
