const ApiBase = require('./api_base').default;

class customerApi extends ApiBase {
    constructor(apiUrl) {
        let props = {
            url: 'get_customer',
            apiUrl: apiUrl,
            authenticated: true,
            method: 'GET',
            headers: {},
            skipName: true
        };
        super(props);
    }
}

function CustomerApi(apiUrl) {
    return new customerApi(apiUrl);
}

export default CustomerApi;