/**
 * Created by woydzl on 12/02/22.
 */
/**
 * Count.js
 *
 * Count class to track an item and it's repetitions.
 *
 */
class Count{
    constructor(item, count=0) {
        this._item = item;
        this._count = count;
    }
    item(){
        return this._item;
    }
    count(){
        return this._count;
    }
    counting(item){
        if (this._item === item) {
            this._count++;
            return true;
        }
        return false;
    }
}

export default Count;