/**
 * TimeFilter.js
 *
 * A time based filter
 */

import {hasKey} from "../Convenience";

/**
 * Time based filtering for outcomes and journeys
 * Currently contains the consolidation of platforms which really doesn't belong here
 *
 * @param {Object[]} values - Array of outcomes or journeys
 * @param key - 'value' or 'occurrences' - Since outcomes and journeys have different
 *              keys within each object, we need to specify if we are filtering
 *              'value' or 'occurrences'
 * @param filter - A filter object with the following structure:
 *                  {
 *                      name: 'time',
 *                      start: 123456789, (optional)
 *                      end: 123456789 (optional)
 *                  }
 *                  This object stores the parameters for the time based filter
 * @returns {[]}
 */
export function getFiltered(values, key, filter) {
    if (!hasKey('name', filter)) return values;
    if (typeof values === 'undefined' || values.length === 0) return [];
    const start = hasKey('start', filter) ? Number(filter.start) : 0;
    const end = hasKey('end', filter) ? Number(filter.end) : 0;
    if (start === 0 && end === 0) return values;
    if (start >= end && end !== 0) return [];
    let filtered = [];
    values.forEach((element) => {
        let newElement = {...element};
        const series = element.series;
        const filteredSeries = series.filter((item) => {
            let includedForStart = start > 0 ? item > start : true;
            let includedForEnd = end > 0 ? item < end : true;
            return includedForStart && includedForEnd;
        });

        //This is used for consolidating platforms which really doesn't belong here
        //We need to move this to its own file in the future
        if (element.platforms) {
            const platformsSeries = Object.keys(element.platforms);
            const filteredPlatforms = {};
            platformsSeries.forEach((item) => {
                let includedForStart = start > 0 ? item > start : true;
                let includedForEnd = end > 0 ? item < end : true;
                if (includedForStart && includedForEnd) {
                    filteredPlatforms[item] = element.platforms[item];
                }
            });

            newElement['platforms'] = filteredPlatforms;
        }
        const value = filteredSeries.length;
        if (value === 0) return;
        newElement[key] = value;
        newElement.series = filteredSeries;
        filtered.push(newElement);
    });
    filtered.sort((a, b) => {
        if (a[key] > b[key]) return -1;
        if (a[key] < b[key]) return 1;
        return 0;
    });
    return filtered;
}