let menuList2 = [
  { title: 'Features', to: '#!' },
  { title: 'Roadmap', to: '#!' },
  { title: 'Changelog', to: '#!' },
  { title: 'Pricing', to: '#!' },
  { title: 'Docs', to: '#!' },
  { title: 'System Status', to: '#!' },
  { title: 'Agencies', to: '#!' },
  { title: 'Enterprise', to: '#!' }
];
export default menuList2;
