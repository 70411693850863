/**
 * Created by woydzl on 03/07/23.
 */
/**
 * ConsolidateForCategories.js
 *
 * Consolidate if outcome has categories.
 *
 */


/**
 * Creates array of all groups, grouped together.
 *
 * @param subject - outcomes
 */
export function consolidateForCategories(outcomes, consolidateFully = true) {
    const consolidatedMap = createCategoryMap(outcomes, consolidateFully);
    let keys = Object.keys(consolidatedMap);
    let consolidated = [];
    keys.forEach(key => consolidated.push(consolidatedMap[key]));
    consolidated.sort((a, b) => b.value - a.value);
    return consolidated;
}

export function mergeValues(existing, value, consolidateFully = true) {
    let merged;
    if (consolidateFully) {
        merged = {
            id: existing.id, //useless
            value: existing.value + value.value,
            name: existing.name,
            uuid: existing.uuid, //useless???
            color: existing.color,
            series: existing.series.concat(value.series),
            platforms: Object.assign({}, existing.platforms, value.platforms),
            tags: Object.assign({}, existing.tags, value.tags),
            originalName: existing.originalName //useless
        };
    }
    else {
        merged = {
            id: existing.id, //useless
            value: existing.value + value.value,
            name: existing.name,
            uuid: existing.uuid, //useless???
            color: existing.color,
            series: existing.series.concat(value.series),
            originalName: existing.originalName //useless
        };
    }
    //if value has children, we take care of it in createCategoryMap()
    if (existing.hasOwnProperty('children') && Array.isArray(existing.children)) {
        merged.children = existing.children;
    }
    return merged;
}

export function structureValue(value, name, originalName, consolidateFully = true) {
    name = name.charAt(0).toUpperCase() + name.slice(1)
    let color;
    if (name === 'Success') {
        color = 'success';
    }
    else if (name === 'Fail') {
        color = 'danger';
    }
    if (consolidateFully) {
        return {
            id: value.id,
            value: value.value,
            name: name,
            uuid: value.uuid,
            color: color ?? value.color,
            series: Object.assign([], value.series),
            platforms: Object.assign({}, value.platforms),
            tags: Object.assign({}, value.tags),
            originalName: originalName
        }
    }
    else {
        return {
            id: value.id,
            value: value.value,
            name: name,
            uuid: value.uuid,
            color: color ?? value.color,
            series: Object.assign([], value.series),
            originalName: originalName
        }
    }
}

export function createCategoryMap(outcomes, consolidateFully) {
    return outcomes.reduce((acc, value) => {
        let category = {};
        let name = value.name;
        let originalName = value.name;
        value.originalName = originalName;
        let pieces = value.name.split(':');

        if (name.includes(':')) {
           name = pieces[0];
        }

        if (acc.hasOwnProperty(name)) {
           category = mergeValues(acc[name], value, consolidateFully);
        }
        else {
            category = structureValue(value, name, originalName, consolidateFully);
        }

        if (value.hasOwnProperty('children')) {
            category.children = setPassedChildren(value.children);
        }

        if (pieces.length > 1) {
            if (Array.isArray(category.children)) {
                //if category.children[n].name exists then replace, else push
                let nextPiece = pieces[1].charAt(0).toUpperCase() + pieces[1].slice(1);
                let existingIndex = category.children.findIndex((element) => element.name === nextPiece);
                if (existingIndex != -1) {
                    category.children[existingIndex] = setChildren(category, pieces.slice(1), value, consolidateFully);
                }
                else {
                    category.children.push(setChildren(category, pieces.slice(1), value, consolidateFully));
                }
            }
            else {
                category.children = [setChildren(category, pieces.slice(1), value, consolidateFully)];
            }
        }
        acc[name] = category;
        return acc;
    }, {});
}

export function setChildren(existing, remainingPieces, value, consolidateFully = true) {
    let child;

    if (existing.hasOwnProperty('children')) {
        let nextPiece = remainingPieces[0].charAt(0).toUpperCase() + remainingPieces[0].slice(1);
        child = existing.children.find((element) => element.name === nextPiece);
    }
    if (child == null) {
        child = structureValue(value, remainingPieces[0], value.originalName, consolidateFully);
    }
    else {
        child = mergeValues(child, value, consolidateFully);
    }

    if (remainingPieces.length > 1) {
        if (child.children == null) {
            child.children = [];
        }
        child.children.push(setChildren(child, remainingPieces.slice(1), value, consolidateFully));
    }

    return child;
}

export function setPassedChildren(children) {
    for(let i = 0; i < children.length; i++) {
        children[i].originalName = children[i].name;
    }
    return children;
}
