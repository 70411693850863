import { isEmptyObject } from "jquery";

let count = 0;
async function sleep(ms) {
    return await new Promise(resolve => setTimeout(resolve('resolved'), ms));
}

async function sleepingApiRetrier(apiCall, callsAllowed, maxTimeMs, multiplier, initialDelayMs) {
    let sleepTime = initialDelayMs * multiplier;
    sleepTime = (sleepTime >= maxTimeMs && maxTimeMs > 0) ? maxTimeMs : sleepTime;
    await sleep(sleepTime);
    return await apiRetrier(apiCall, callsAllowed - 1, maxTimeMs, multiplier, sleepTime);
}

export default async function apiRetrier(apiCall, callsAllowed = -1, maxTimeMs = -1, multiplier = 2, initialDelayMs = 500) {
    count++;
    const start = Date.now();
    if (callsAllowed === 0) {
        return [];
    }
    let response;
    try {
        response = await apiCall();
    } catch(error) {
        throw error;
    }

    let type = Object.prototype.toString.call(response).slice(8, -1);

    switch (type) {
        case 'Map':
            return response.size === 0 ? await sleepingApiRetrier(apiCall, callsAllowed, maxTimeMs, multiplier, initialDelayMs) : response; 
        case 'Array':
            return response.length === 0 ? await sleepingApiRetrier(apiCall, callsAllowed, maxTimeMs, multiplier, initialDelayMs) : response; 
        case 'Object':
            return isEmptyObject(response) ? await sleepingApiRetrier(apiCall, callsAllowed, maxTimeMs, multiplier, initialDelayMs) : response; 
        default:
            throw new Error('No call made.');
    }
}