const ApiBase = require('./api_base').default;

class lastEventsApi extends ApiBase {
    constructor(apiUrl) {
        let props = {
            name: 'ApiLastEvents',
            url: 'last_events',
            apiUrl: apiUrl,
            authenticated: true
        };
        super(props);
    }
}

function LastEventsApi(apiUrl) {
    return new lastEventsApi(apiUrl);
}

export default LastEventsApi;