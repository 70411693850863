/**
 * Created by woydzl on 12/05/22.
 */
/**
 * CsvDownload.js
 *
 * Component for downloading a csv from an array.
 *
 */
import {CSVLink} from 'react-csv';
import React, {useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import {BsFiletypeCsv} from "react-icons/bs";
import classNames from 'classnames';
import PropTypes from 'prop-types';

export async function* serialApiCalls(calls) {
    for (let call of calls) {
        yield await call();
    }
}

export async function arrayFrom(asyncIterator) {
    let arr = [];
    let result = await asyncIterator.next();
    while (!result.done) {
        arr.push(result.value);
        result = await asyncIterator.next();
    }
    return arr;
}

const CsvDownload = ({
                         headers,
                         getCsvData,
                         className = '',
                         filename = 'data.csv',
                         style = {},
                         log = console.log
                     }) => {
    const [loading, setLoading] = useState(false);
    const [csvData, setCsvData] = useState([]);

    return (<OverlayTrigger
        placement="top"
        overlay={<Tooltip style={{position: 'fixed'}}>
            <span className="csv-tooltip">Download CSV</span>
        </Tooltip>}>
        {({ref, ...triggerHandler}) => (
            <CSVLink
                ref={ref}
                {...triggerHandler}
                data={csvData}
                headers={headers}
                asyncOnClick={true}
                onClick={async (event, done) => {
                    setCsvData([]);
                    setLoading(true);
                    try {
                        if (Array.isArray(getCsvData)) {
                            const csvDataArray = await arrayFrom(serialApiCalls(getCsvData));
                            const combinedCsvData = csvDataArray.flat(1);
                            setCsvData(combinedCsvData);
                        } else {
                            const newCsvData = await getCsvData();
                            setCsvData(newCsvData);
                        }
                        setLoading(false);
                        done(true);
                    } catch (e) {
                        log('Error getting CSV data:', e);
                        setLoading(false);
                        done(false);
                    }
                }}
                className={classNames(className, 'csv-download')}
                filename={filename}
            >
                {loading ?
                    <Spinner className="csv-spinner" animation="border" size="xs" style={style}/> :
                    <BsFiletypeCsv className="csv-link-icon" style={style}/>}
            </CSVLink>)}
    </OverlayTrigger>);
};

CsvDownload.propTypes = {
    headers: PropTypes.array.isRequired,
    getCsvData: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
    className: PropTypes.string,
    filename: PropTypes.string,
    style: PropTypes.object,
    log: PropTypes.func
};


export default CsvDownload;
