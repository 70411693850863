import React, {useContext, useEffect, useState} from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import CustomerApi from "../../../../api/customer";
import apiRetrier from 'api/api_retrier';


const TopNavRightSideNavItem = ({
                                  customerApi_ = CustomerApi,
                                  customer_ = '',
                                  errorLog_ = console.log,
                                  maxApiRetry_ = -1
                                }) => {
    const [customer, setCustomer] = useState(customer_);
    const {
        config: { isDark, customerCode, apiUrl},
        setConfig
    } = useContext(AppContext);

    const maxApiTime = 180000; //3 minutes

    function getCustomer(data) {
        apiRetrier(() => customerApi_(apiUrl).fetch({data}), maxApiRetry_, maxApiTime)
          .then(response => {
              setCustomer(response);
          })
          .catch(error => {
              errorLog_(error);
          });
    }

    function setUpDemo() {
        setCustomer({
            name: "demo",
            email: "demo@email.com"
        });
    }

    useEffect(() => {
        if (customerCode === 'demo') {
            setUpDemo();
            return;
        }

        if (customerCode) {
            let data = {token: customerCode};
            getCustomer(data);
        }

    }, [customerCode]);

  return (
    <Nav
      navbar
      className="top-nav-right-side-nav-item navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item
        className={"customer-name"}
      >
          {customer.name}
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={'div'}
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            overlay={
              <Tooltip id="hi">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
            placement="left"
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      {
        customerCode !== '' && (
        <ProfileDropdown />
      )}
    </Nav>
  );
};

export default TopNavRightSideNavItem;
