import React from 'react';
import PropTypes from 'prop-types';
import gravatar from 'gravatar';
import Url from 'url';

class Gravatar extends React.Component {
    static displayName = 'Gravatar';
    static propTypes = {
        email: PropTypes.string,
        gravatarUrl: PropTypes.string,
        size: PropTypes.number,
        rating: PropTypes.string,
        defaultImg: PropTypes.string,
        className: PropTypes.string,
    };
    static defaultProps = {
        size: 50,
        rating: 'g',
        defaultImg: 'retro'
    };

    basename(path) {
        // Split a filename into [root, dir, basename, ext], unix version
        // 'root' is just a slash, or nothing.
        let splitPathRe =
            /^(\/?|)([\s\S]*?)((?:\.{1,2}|[^/]+?|)(\.[^./]*|))(?:[/]*)$/;

        let split = splitPathRe.exec(path).slice(1);
        return split[2];
    }

    render() {
        const {email, gravatarUrl, size, rating, defaultImg, className} = this.props;
        const thisClass = className + '-gravatar-img';
        let gravatarHash = null;
        if (gravatarUrl) {
            const gravatarUrlParsed = Url.parse(gravatarUrl);
            const file = gravatarUrlParsed.pathname;
            gravatarHash = this.basename(file);
        }
        // Gravatar service currently trims and lowercases all registered emails
        let formattedEmail = 'garbage_email_1232144dlmerwrwe@example.com';
        if (email && email !== '') formattedEmail = ('' + email).trim().toLowerCase();
        if (gravatarHash) formattedEmail = gravatarHash;
        const url = gravatar.url(formattedEmail, {
            s: size,
            r: rating,
            d: defaultImg,
        }, true);
        const urlSet = gravatar.url(formattedEmail, {
            s: size * 2,
            r: rating,
            d: defaultImg,
        }, true);

        let {...rest} = this.props;
        delete rest.email;
        delete rest.rating;
        delete rest.size;
        delete rest.defaultImg;
        delete rest.parentClass;
        delete rest.features;
        delete rest.gravatarUrl;

        return (
            <img
                alt={`Gravatar for ${formattedEmail}`}
                src={url}
                srcSet={`${urlSet} 2x`}
                height={size}
                width={size}
                {...rest}
                className={thisClass}
            />
        );
    }
}


export default Gravatar;