import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import AppContext from 'context/Context';
import code from 'assets/img/icons/spot-illustrations/48.png';


const EnterCode = () => {
    const {
        setConfig
    } = useContext(AppContext);

    const classNameBase = 'enter-customer-code-card';

    const [customerCode, setCustomerCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);

    const navigate = useNavigate();

    const submit = (e) => {
        e.preventDefault();
        setConfig('customerCode', customerCode);
        setCustomerCode('');
        navigate('/discover-r');
    };

    function getErrorMessage(value) {
        const {length} = value;
        if (value === 'demo') return '';
        if (length < 1) return '';
        if (length <= 225) return 'Customer Code is incomplete.';
        return '';
    }

    function enabledClass() {
        return (canSubmit ? '' : ' disabled');
    }

    function getSubmittalState(value, message) {
        let submit = false;
        if (value.length > 0 && message === '') submit = true;
        return submit;
    }

    const change = (e, setter) => {
        const {value} = e.target;
        let message = getErrorMessage(value);
        let submit = getSubmittalState(value, message);
        setCanSubmit(submit);
        setErrorMessage(message);
        setter(value);
    };

    const errorClass = () => {
        return ((errorMessage === '') ? '' : ' is-invalid');
    };

    const errorStyle = () => {
        return ( (errorMessage === '') ? {} : {display: 'inline-flex'});
    };
    return (
        <>
            <Card className={classNameBase}>
                <Card.Body className="text-center py-5">
                    <Row className="justify-content-center">
                        <Col xs={7} md={5}>
                            <img className="img-fluid" src={code} alt="enter code" width="58%"/>
                        </Col>
                    </Row>
                    <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
                        Enter your customer code to view your Dashboard
                    </h3>
                    <p className="lead mb-5">
                        Get started viewing your results by entering your customer code.
                    </p>
                    <Row className="justify-content-center">
                        <Col md={7}>
                            <Form as={Form} className="row g-2">
                                <Col sm>
                                    <Form.Control
                                        type="password"
                                        placeholder="Customer Code"
                                        aria-label="customer code"
                                        name="password"
                                        autoComplete="one-time-code"
                                        className={classNameBase + '-entry' + errorClass()}
                                        onChange={(e) => change(e, setCustomerCode)}
                                    />
                                </Col>
                                <Col sm="auto">
                                    <Button variant="primary" className={classNameBase + '-button d-block w-100' + enabledClass()}
                                            type="submit" onClick={submit}>
                                        Go To Dashboard
                                    </Button>
                                </Col>
                                <Row>
                                    <small className={'invalid-feedback ' + classNameBase + '-invalid-code'}
                                           style={errorStyle()}>
                                        {errorMessage}
                                    </small>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default EnterCode;
