/**
 * Created by woydzl on 12/02/22.
 */
/**
 * PreviousCounter.js
 *
 * Counter to count adjacent duplicates in the previous list.
 *
 */
import Count from 'utilities/Count';

export default function PreviousCounter(previous) {
    if (!previous) return [];
    const counts = [];
    let counter = null;
    previous.forEach(step => {
        if (!counter) counter = new Count(step);
        if (!counter.counting(step)) {
            counts.push(counter);
            counter = new Count(step, 1);
        }
    });
    if (counter) counts.push(counter);
    return counts;
}