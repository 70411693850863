const ApiBase = require('./api_base').default;

class journeysApi extends ApiBase {
    constructor(apiUrl) {
        let props = {
            name: 'ApiJourneys',
            url: 'journeys',
            apiUrl: apiUrl,
            authenticated: true
        };
        super(props);
    }

    params(data) {
        return {outcomeHash: data.outcomeHash, bucket: data.bucket};
    }
}

function JourneysApi(apiUrl) {
    return new journeysApi(apiUrl);
}

export default JourneysApi;