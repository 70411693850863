let menuList1 = [
  { title: 'About', to: '#!' },
  { title: 'Contact', to: '#!' },
  { title: 'Careers', to: '#!' },
  { title: 'Blog', to: '#!' },
  { title: 'Terms', to: '#!' },
  { title: 'Privacy', to: '#!' },
  { title: 'Imprint', to: '#!' }
];

export default menuList1;